import React, { Component } from "react";
import _ from "lodash";
import cn from "classnames";
import { withRouter } from "react-router-dom";
import OverviewPanel from "./overview";
import SubscriptionPanel from "./subscription";
import GiftsPanel from "./gifts";
import PlansPanel from "./plans";
import ReceiptsPanel from "./receipts";
import NotificationsPanel from "./notifications";
import DataUsagePanel from "./data-usage";
import Tickets from "./tickets";
import { hasSubscriptionInfo } from "../user";
import {
  fetchInitialTickets,
  fetchExclusiveTickets,
  fetchReceipts,
  hasAuth,
  fetchPlanData,
  resetPaymentProcess,
} from "../services/api.js";
import Login from "../services/login";
import { updateMetadata, resetMetadata } from "../actions/metas";
import { connect } from "react-redux";
import {getPlan}          from '../plans'
import {NYA_FREE,NYA_YEARLY, NYA_MONTHLY,NYA_UNLIMITED } from '../util/contants/url_constants'
import ClaimGift from "./claim-gift";
import FreeTrial from "./free-trial";

class AccountInfo extends Component {
  constructor(props, ctx) {
    super(props, ctx);

    this.goto = this.goto.bind(this);
    this.updateDefaultRoute = this.updateDefaultRoute.bind(this);
    this.addAssignedCodes = this.addAssignedCodes.bind(this);
    this.contentForRoute = this.contentForRoute.bind(this);

    this.state = {
      ticketsData: {
        tickets: [],
        ticketsRequestedForCode: [],
        assignedCodes: [],
      },
      receipts: [],
      plans: [],
      purchasedPlan: {},
    };

    const allUserTabs = [
      { name: "Free Trial", route: "free-trial", hidden: true },
    ];

    this.anonymousUserTabs = [
      { name: "Plans", route: "plans" },
      { name: "Tickets", route: "tickets" },
      { name: "Claim Gift", route: "claim-gift" },
      ...allUserTabs,
    ];

    this.freeUserTabs = [
      { name: "Account overview", route: "overview" },
      { name: "Subscription", route: "subscription" },
      { name: "Plans", route: "plans" },
      { name: "Receipts", route: "receipts" },
      { name: "Notification settings", route: "notifications" },
      { name: "Gift", route: "gift" },
      { name: "Claim Gift", route: "claim-gift" },
      { name: "Tickets", route: "tickets" },
      { name: "My data", route: "myData" },
      { name: "Help", route: "help" },
      ...allUserTabs,
    ];

    this.paidUserTabs = [
      { name: "Account overview", route: "overview" },
      { name: "Subscription", route: "subscription" },
      { name: "Plans", route: "plans" },
      { name: "Receipts", route: "receipts" },
      { name: "Notification settings", route: "notifications" },
      { name: "Gift", route: "gift" },
      { name: "Claim Gift", route: "claim-gift" },
      { name: "Tickets", route: "tickets" },
      { name: "My data", route: "myData" },
      { name: "Help", route: "help" },
      ...allUserTabs,
    ];
  }
  UNSAFE_componentWillUpdate() {
    this.updateDefaultRoute();
  }
  componentWillMount() {
    this.updateDefaultRoute();
    hasAuth() ? updateMetadata({ title: "My account" }) : resetMetadata();
  }
  componentDidMount() {
    // Obtener la query desde location.search en lugar de location.query
    const searchParams = new URLSearchParams(this.props.location.search);
    const relogin = searchParams.get("relogin");

    if (hasAuth()) {
      fetchExclusiveTickets()
        .then((result = {}) => {
          const { body: ticketsData } = result;
          this.setState({ ticketsData });
        })
        .catch((err) => {
          console.error(err);
        });

      fetchReceipts()
        .then((receipts) => {
          this.setState({ receipts });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      fetchInitialTickets()
        .then((result = {}) => {
          const { body: ticketsData } = result;
          this.setState({ ticketsData });
        })
        .catch((err) => {
          console.error(err);
        });
    }
    //fetch plans data for all users
    fetchPlanData().then((plans) => {
      this.parsePlans(plans);
    });

    if (relogin && !hasAuth()) {
      setTimeout(() => !hasAuth() && Login("/account?screen=overview"), 1500);
    }
  }
  parsePlans(plansInfo) {
    let plansAvailable = {};
    let planInformation = plansInfo.planInformation
    let plans = plansInfo.planPrices
    let purchasedPlan ={
        price:'0.00',
        name: NYA_FREE
    }
    const {userPlanId,relevantSubscriptionDate, hasPlanExpired} = this.props.userData
    for (let i = 0; i < plans.length; i++) {
      let plan = plans[i];
      let planType = plan.product_id ? plan.product_id.split(/\W|_/g) : null;
      if (planType || userPlanId == NYA_FREE ) {
        if (!plan.interval) plan.interval = "month";
        planType = `NYA-${planType[planType.length - 1]}`;
        // basic Plans don't have special terminology like VIDEO ,PATREON
        if (planType == NYA_YEARLY || planType == NYA_MONTHLY) {
            planType = NYA_UNLIMITED;
        }
        if (!plansAvailable[planType]) {
          plansAvailable[planType] = plansAvailable[planType] || [];
          plansAvailable[planType]['plan'] = []
          plansAvailable[planType]['prices'] = []
          plansAvailable[planType]['yearPrice'] = 0
        }
        plansAvailable[planType]['planName'] = planType

        if(plan.interval == "year"){
            plansAvailable[planType]["plan"].push(plan);
        }else{
            plansAvailable[planType]["plan"].unshift(plan);
        }

          //Just showing year prices
          if (plan.interval === "year" && !["NYA-UNLIMITED-MONTHLY", "NYA-UNLIMITED-YEARLY", "NYA-UNLIMITED-YEARLY-RUST"].includes(plan.product_id)) {
            plansAvailable[planType]['yearPrice'] = parseFloat(plan.price);
            plansAvailable[planType]["prices"].push(
              <span className="plan-price" key={plan.product_id}>
                ${plan.price}/{plan.interval}
              </span>
            );
          }
      }
      //check the type of subscription the user has and it's not expired
      if((userPlanId == plan.product_id) && !hasPlanExpired){
        purchasedPlan = plan
        purchasedPlan.planType = planType
        purchasedPlan.planTitle = 'NYA FREE'
        purchasedPlan.renewalDate = relevantSubscriptionDate
        let planTitle = getPlan(planType)
        if(planTitle && planTitle.displayName) purchasedPlan.planTitle = `NYA ${planTitle.displayName.toLowerCase()}`
      }
    }

    this.setState({plansAvailable, planInformation, purchasedPlan })
  }
  goto(route) {
    // Actualizar navegación usando history
    if (route === "help") {
      this.props.history.push("/contact");
    } else {
      this.props.history.push(`/account?screen=${route}`);
    }
  }
  updateDefaultRoute() {
    this.defaultRoute = hasAuth()
      ? { name: "Account overview", route: "overview" }
      : { name: "Plans", route: "plans" };
  }
  addAssignedCodes({ ticket, code }) {
    let ticketsData = { ...this.state.ticketsData };
    ticketsData.assignedCodes.push({ ticket, code });
    this.setState({ ticketsData });
  }
  contentForRoute(route, view) {
    const { ticketsData, plansAvailable, planInformation, purchasedPlan } =
      this.state;
    const { history, location, userData } = this.props;
    // Obtener screen y view desde location.search
    const searchParams = new URLSearchParams(location.search);
    const entryID = searchParams.get("ticket");

    resetPaymentProcess();

    switch (route) {
      case "overview":
        return <OverviewPanel 
                  purchasedPlan={purchasedPlan}
                  history={history} 
               />;
      case "subscription":
        return (
          <SubscriptionPanel
            history= {history}
            purchasedPlan={purchasedPlan}
            userData={userData}
          />
        );
      case "plans":
        return (
          <PlansPanel
            view={view}
            location={location}
            history={history} // Pasar history en lugar de router
            plansAvailable={plansAvailable}
            planInformation={planInformation}
            purchasedPlan={purchasedPlan}
            userData={userData}
          />
        );
      case "receipts":
        return <ReceiptsPanel />;
      case "notifications":
        return <NotificationsPanel />;
      case "gift":
        return <GiftsPanel history={history} plansAvailable={plansAvailable} />;
      case "myData":
        return <DataUsagePanel />;
      case "tickets":
        return (
          <Tickets
            data={ticketsData}
            accountPage={this.accountPage}
            entryID={entryID}
            addAssignedCodes={this.addAssignedCodes}
            userData={userData}
          />
        );
      case "claim-gift":
        return (
          <ClaimGift
            location={location}
            history={history}
            plansAvailable={plansAvailable}
            purchasedPlan={purchasedPlan}
            userData={userData}
          />
        );
      case "free-trial":
        return (
          <FreeTrial
            history={history}
            userData={userData}
            plansAvailable={plansAvailable}
          />
        );
      default:
        return null;
    }
  }
  render() {
    // Obtener screen y view desde location.search
    const searchParams = new URLSearchParams(this.props.location.search);
    const screen = searchParams.get("screen");
    const view = searchParams.get("view");

    const { receipts } = this.state;
    const tabs =
      hasSubscriptionInfo() || receipts.length > 0
        ? this.paidUserTabs
        : hasAuth()
        ? this.freeUserTabs
        : this.anonymousUserTabs;
    const selectedRoute =
      _.find(tabs, (t) => t.route === screen) || this.defaultRoute;

    const { route } = selectedRoute;

    return (
      <div ref={(el) => (this.accountPage = el)} className="account-info-page">
        <div className="account-info">
          <span className="tape tape--1"></span>
          <span className="tape tape--2"></span>
          <span className="tape tape--3"></span>
          <span className="tape tape--4"></span>
          <div className="tabs">
            {tabs.map((t, idx) => {
              const selected = selectedRoute === t;
              const { name, route, hidden = false } = t;
              if (hidden) return null;
              return (
                <div
                  className={cn("tab", { selected })}
                  onClick={() => this.goto(route)}
                  key={idx}
                >
                  {name}
                </div>
              );
            })}
          </div>
          <div className="tab-content">
            {this.contentForRoute(route, view)}
            <div className="preload-font">A</div>
            <div className="preload-font-1">A</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return { userData: state.userData };
};

export default withRouter(connect(mapStateToProps)(AccountInfo));
